import React, { useState } from 'react';
import Container from '../components/layout/default/container';
import CtaBrand from '../components/marketing/cta-brand';
import SEO from '../components/layout/seo';
import AutoForm from '../components/form/auto-form';
import Alert from '../components/alerts/alert';

  const contactForm = {
    type: 'post',
    postRoute: `/contact/form`,
    successNotification: 'We have received your message and will get back to you shortly.',
    fields: [
      {
        name: 'first_name',
        label: 'First Name',
        type: 'text',
        required: true,
      },
      {
        name: 'last_name',
        label: 'Last Name',
        type: 'text',
        required: true,
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email',
        required: true,
      },
      {
        id: 'phone',
        name: 'phone',
        label: 'Cell Phone',
        type: 'phone',
        // required: true,
      },
      {
        name: 'message',
        label: 'Message',
        type: 'textarea',
        required: true,
      },
    ],
    submitLabel: `Send Message`,
  };

// markup
const IndexPage = () => {
  const [submittedForm, setSubmittedForm] = useState(false);

  const showMessage = () => {
    setSubmittedForm(true);
  };

  contactForm.successHandler = showMessage;

  return (
    <Container>
      <SEO title="Contact Us" />

      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
          <h1 className="text-2xl mb-4 text-gray-800">Contact Us</h1>
          <p className="mb-4">
          Do you have questions or comments?  Use this form to reach out, and we will get back to you ASAP.
          </p>
          {!submittedForm && (
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              <AutoForm {...contactForm} />
            </div>
          )}
          {submittedForm && <Alert type="success" title="Thank you!" body="We have received your message and we will get back to you as soon as possible." />}
        </div>
      </div>

      <div>
        <CtaBrand
          img="https://images.unsplash.com/photo-1463411563105-157075b06f96?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
          alt="Bowlers"
          headline="Ready to have fun?"
          headline2="Find a bowling league near you!"
          details="Browse through our nationwide bowling centers to find a league starting soon near you."
          href="/leagues"
          btnText="Find Leagues Now"
        />
      </div>
      
    </Container>
  );
}

export default IndexPage
